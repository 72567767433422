import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyA3G5V4NPsGdMtcYhK0lmXD1ma4wAelNgs",
  authDomain: "sdasadsa-12870.firebaseapp.com",
  projectId: "sdasadsa-12870",
  storageBucket: "sdasadsa-12870.appspot.com",
  messagingSenderId: "1000560371402",
  appId: "1:1000560371402:web:786b0576165caa7043b633"
};

firebase.initializeApp(firebaseConfig)

export const database = firebase.database()
export const auth = firebase.auth()
